
import React, { useState, useEffect } from 'react';

// routing
import { useParams, useHistory, useLocation } from 'react-router-dom';

// redux
import { useDispatch, useSelector } from 'react-redux';
import globalErrorsActions from "../../../../services/redux/actions/globalErrors.action";

// networking
import takenExamsApi from "../../../../services/Networking/takenExams";

// services
import convertError from "../../../../services/errors/convertError";

// services local
import fetchExams from "../../services/networking/exams/fetchExams";
import postTakenExam from "../../services/networking/takenExams/postTakenExam";
import sendableUserAnswers from "../../services/sendableUserAnswers";

// errors local
import handInErrors from "../../services/errors/handInErrors";

// style
import "./TakeExam.scss";

// classes local
import StickyButtonClass from "../../components/StickyButtons/StickyButtonClass";

// classes
import buttonClass from "../../../../components/Alert/services/classes/ButtonClass";
import infoColumnClass from '../../components/ExamInfo/services/classes/infoColumnClass';
import infoRowClass from '../../components/ExamInfo/services/classes/infoRowClass';
import errorClass from '../../../../services/errors/classes';

// services
import translateExamCategory from '../../services/translateExamCategory';
import networking from '../../../../services/handleNetworking/networking';
import authApi from '../../../../services/Networking/authentication/authentication';
import handleEnvironment from '../../../../services/handleEnvironment/handleEnvironment';
import examEvaluation from '../../../../services/Networking/examEvaluation/examEvaluation';
import handleLocalStorage from '../../../../services/localStorage';

// components local
import Question from "../../components/Question";
import StickyButtons from "../../components/StickyButtons";
import ExamInfo from "../../components/ExamInfo";

// components
import Title from "../../components/Title";
import Loader from '../../../../components/Loader';
import List from "../../../../components/List";
import LocalModal from "../../../../components/LocalModal";
import Alert from "../../../../components/Alert";
import SetUserSettings from '../../components/SetUserSettings/SetUserSettings';
import Breadcrumb from "../../../../components/Breadcrumb";
import UnsavedExamChanges from '../../components/UnsavedExamChanges/UnsavedExamChanges';
import GenerateFeedbackLoading from '../../components/GenerateFeedbackLoading/GenerateFeedbackLoading';
import Redux from '../../../../components/Redux/Redux';

// constants
import { SUCCESS, NO_RESPONSE, INTERNAL_ERROR } from "../../../../services/errors/constants";
import { MAKE, CHECK} from "../../services/tabConstants";
import { ASSAY_QUESTION, MULTIPLE_CHOICE_QUESTION } from "../../services/constants/typeConstants";
import { SAVE, SUBMIT } from './constants/constants';
import { PUBLISHED } from '../../../../services/constants/stateConstants';
import { ALLOW_GENERATED_FEEDBACK_KEY } from '../../../MyProfile/components/Settings/utils/constants';
import { ETALIA_ENV, UVA_ENV } from '../../../../services/handleEnvironment/constants';
import { ADD_LOCAL_STORAGE, REMOVE_LOCAL_STORAGE } from '../../../../services/localStorage/constants';
import { LAST_ERROR_GENERATED_FEEDBACK } from '../../services/constants/feedbackGeneration';
import { REDUX_ERRORS } from '../../../../components/Redux/services/constants';

const TakeExam = (props) => {

    // redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer);

    // routing
    let history = useHistory();
    let location = useLocation();

    // id's
    let { tijdvakID, kaID, examID, oderNumb } = useParams();
    const topicId = parseInt(tijdvakID?.replace("t-", ""));
    const subTopicId = parseInt(kaID?.replace("st-", ""));
    const examId = examID?.replace("e-", "");
    const orderId = parseInt(oderNumb);

    // alerts
    const [showHandInAlert, toggleShowHandInAlert] = useState(0);

    // exam
    const [examView, updateExamView] = useState(MAKE);
    const [showExamInfo, toggleShowExamInfo] = useState(false);
    const [examClass, updateExamClass] = useState(null);
    const [questionsHubs, updateQuestionHubs] = useState([]);

    // data
    const [userSettings, updateUserSettings] = useState(null);

    // states
    const [showUnsavedWarning, toggleShowUnsavedWarning] = useState(false);
    const [showUserSettings, toggleShowUserSettings] = useState(null);
    const [showUserSettingsBeforeSubmission, toggleShowUserSettingsBeforeSubmission] = useState(false);
    const [showLoadingGenerateFeedback, toggleShowLoadingGenerateFeedback] = useState(false);

    // loading
    const [loading, toggleLoading] = useState(true);
    const [loadingSaving, toggleLoadingSaving] = useState(false);
    const [loadingSubmitting, toggleLoadingSubmitting] = useState(false);
    const [loadingUserSettings, toggleLoadingUserSettings] = useState(true);

    // errors
    const [generateFeedbackError, updateGenerateFeedbackError] = useState(null);

    // inside every question
    const [showQuestionInfo, toggleShowQuestionInfo] = useState(false);

    const [userAnswers, updateUserAnswers] = useState([]);

    useEffect(() => {
        settingUpExam();
        fetchUserSettings();
    }, []);

    const settingUpExam = async() => {

        const returnedExamInfo = await fetchExams({
            examId: examId,
            state: PUBLISHED,
            
            handleApiReturn: handleApiReturn, 
            updateExamClass: updateExamClass, 
            updateQuestionHubs: updateQuestionHubs, 
        });

        if (!returnedExamInfo) {
            return false;
        }

        // // add pre-existing user answers to userAnswers
        // createUserAnswers({
        //     questions: returnedExamInfo.questions,
    
        //     updateUserAnswers: updateUserAnswers
        // });

    }

    const handleApiReturn = async(returned, handingIn) => {

        dispatch(globalErrorsActions.emptyErrorList());
        
        if (!returned) {

            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: NO_RESPONSE,
                    customErrors: null
                })
            ));

            // something weard happend
            toggleLoading(false);
            return false;
        }

        if (returned.status === SUCCESS) {
            
            if (handingIn) {
                dispatch(globalErrorsActions.addError(
                    convertError({
                        errorCode: returned.status,
                        customErrors: handInErrors
                    })
                ));
            }

            toggleLoading(false);
            return true;

        } else {
            dispatch(globalErrorsActions.addError(
                convertError({
                    errorCode: returned.status
                })
            ));
        }

        toggleLoading(false);
        return false;

    }

    const renderQuestion = (i, questionHub) => {

        if (!questionHub) {
            return null;
        }

        return (
            <Question
                key={i}

                examView={examView}

                handedIn={showHandInAlert}

                questionHub={questionHub}

                showQuestionInfo={showQuestionInfo}
                toggleShowQuestionInfo={toggleShowQuestionInfo}
            />
        )
    }

    const sendUserAnswers = async(state, currentUserSettings) => {
        if (state === SUBMIT) {
            toggleLoadingSubmitting(true);
        }

        const toSend = sendableUserAnswers(questionsHubs, true);

        const sendSuccessfull = await postTakenExam({
            examId,
            userAnswers: toSend,
            state,
    
            handleApiReturn: handleApiReturn,
        });

        if (sendSuccessfull && state === SUBMIT) {

            // fetch taken exam -- TODO use fetch latest order!!!
            const returned = await takenExamsApi.getTakenExamOrdersByExamId(examClass.uuid);
            
            if (returned) {
                if (returned.status === SUCCESS && returned.payload) {
                    
                    const examJustSent = returned.payload[0];
                    if (currentUserSettings?.[ALLOW_GENERATED_FEEDBACK_KEY] === true) {
                        toggleShowLoadingGenerateFeedback(true);
                        await feedbackGeneren(examJustSent);
                    }
                    
                    history.push(`${location.pathname.replace(`e-${examId}/Examen-Maken`, `e-${examClass?.uuid}/${examJustSent}/Examen-Nakijken`)}`);
                    
                    toggleLoadingSaving(false);
                    toggleLoadingSubmitting(false);
                    return null;
                }
            }

            history.push(`${location.pathname.replace(`e-${examId}/Examen-Maken`, `e-${examClass?.uuid}/#${CHECK}`)}`);
        }

        toggleLoadingSaving(false);
        toggleLoadingSubmitting(false);

    }

    const feedbackGeneren = async(order) => {
        handleLocalStorage({
            action: REMOVE_LOCAL_STORAGE,
            key: LAST_ERROR_GENERATED_FEEDBACK,
        });
        if (handleEnvironment() === ETALIA_ENV) {
            return;
        }

        const feedback = await examEvaluation.postExamEvaluation(examId, order);
        if (!feedback || feedback.status !== SUCCESS) {
            handleLocalStorage({
                action: ADD_LOCAL_STORAGE,
                key: LAST_ERROR_GENERATED_FEEDBACK,
                data: new Date()
            });
            updateGenerateFeedbackError(new errorClass({
                errorCode: INTERNAL_ERROR,
                description: "Feedback genereren niet gelukt. Het kan zijn dat er te veel studenten tegelijkertijd feedback aan het genereren zijn. Probeer het zo opnieuw."
            }));
        }
        return feedback;
    }

    const submitExam = (latestUserSettings) => {
        toggleLoadingSubmitting(true);

        if (!latestUserSettings && handleEnvironment() === UVA_ENV) {
            toggleShowUserSettingsBeforeSubmission(true);
            toggleLoadingSubmitting(false);
            return;
        }

        // check if all answers filled
        const skippedQuestion = allAnswerFilled();

        if (skippedQuestion) {
            // false +1 is 1 in JS, so it aint pretty but it works with one var
            toggleShowHandInAlert(value => value + 1);
            toggleLoadingSubmitting(false);
        } else {
            sendUserAnswers(SUBMIT, latestUserSettings);
        }
    }

    const saveExam = () => {
        toggleLoadingSaving(true);
        sendUserAnswers(SAVE);
    }

    const allAnswerFilled = () => {

        let questionSkipped = false;

        questionsHubs.map((questionHub) => {

            if (questionHub.getCurrentValue("type") === ASSAY_QUESTION) {
                // test if empty string is also true!!!
                if (!questionHub.studentAnswer) {
                    questionSkipped = true;
                }
            } else if (questionHub.getCurrentValue("type") === MULTIPLE_CHOICE_QUESTION) {
                if (questionHub.answerId.length < 1) {
                    questionSkipped = true;
                }
            }
        });

        return questionSkipped;
    }

    const close = (forceClose) => {

        // TODO: not functional until student answer is implemented inside student answer

        // if (!forceClose && examChanged({
        //     exam: examClass,

        //     examState: examClass.state,
        //     examId: examId,
        //     questionHubs: questionsHubs
        // })) {
        //     toggleShowUnsavedWarning(true);
        //     return;
        // }

        history.goBack();

        // check if unsaved changes - `/geschiedenis/t-${topicId}/st-${subTopicId}/e-${examId}`
        // history.push(`/geschiedenis/${tijdvakID}/${kaID}#6`);

    }

    const fetchUserSettings = async() => {
        const response = await networking({
            toggleLoading: toggleLoadingUserSettings,

            api: authApi.getUserSettings,
            apiParams: user?.user?.userId,

            updateContent: updateUserSettings,
        });
        if (response && response.status === SUCCESS) {
            return response.payload;
        }
        return null;
    }

    return (
        <div className="TakeExam" >

            <Breadcrumb />

            <StickyButtons
                buttons={[
                    loadingSaving ? new StickyButtonClass({

                        title: <Loader />,
                        onClickFunc: null,
                        link: null,
                
                    }) : new StickyButtonClass({

                        title: "Opslaan",
                        onClickFunc: saveExam,
                        link: null,
                
                    }),
                    loadingSubmitting ? new StickyButtonClass({

                        title: <Loader />,
                        onClickFunc: null,
                        link: null,
                
                    }) : new StickyButtonClass({

                        title: "Inleveren",
                        onClickFunc: () => submitExam(userSettings),
                        link: null,
                
                    }),
                    new StickyButtonClass({

                        title: "Sluiten",
                        onClickFunc: close,
                        link: null,
                
                    })
                ]}
            />

            {
                loading ? <Loader /> :
                !examClass ? null :
                <Title
                    title={examClass.title}

                    showExamInfo={showExamInfo}
                    toggleShowExamInfo={toggleShowExamInfo}
                />
            
            }


            {
                loading ? null : !showExamInfo ? null : !examClass ? null :
                <ExamInfo
                    examClass={examClass}
                    infoColumns={[
                        new infoColumnClass({
                            title: examClass?.grade ? "Toets Info" : "Info",
                            infoRowList: [
                                new infoRowClass({
                                    title: "Vragen",
                                    value: examClass?.questions?.length
                                }),
                                new infoRowClass({
                                    id: "category",
                                    title: "Categorie",
                                    value: translateExamCategory(examClass?.category)
                                }),
                                new infoRowClass({
                                    title: "Punten",
                                    value: examClass.points
                                }),
                            ],
                        }),
                        new infoColumnClass({
                            title: "Bewerker",
                            infoRowList: [
                                new infoRowClass({
                                    value: "EtAlia"
                                }),
                            ],
                        }),
                        ...(loadingUserSettings ? [] : [new infoColumnClass({
                            title: "Instellingen",
                            infoRowList: [
                                new infoRowClass({
                                    title: "Feedback Genereren",
                                    value: userSettings === null ? "Niet aangegeven" : userSettings[ALLOW_GENERATED_FEEDBACK_KEY] === true ? "Ja" : "Nee",
                                }),
                                new infoRowClass({
                                    title: "AI trainen",
                                    value: userSettings === null ? "Niet aangegeven" : userSettings[ALLOW_GENERATED_FEEDBACK_KEY] === true ? "Ja" : "Nee",
                                }),
                                new infoRowClass({
                                    title: "Instellingen aanpassen",
                                    onClick: () => toggleShowUserSettings(true),
                                }),
                            ]
                        })]),
                    ]}
                />
            }

            {
                !questionsHubs ? null :
                questionsHubs.length === 0 ?
                ""
                :
                <List
                    items={questionsHubs}
                    renderItem={renderQuestion}
                />
            }

            <UnsavedExamChanges
                showUnsavedWarning={showUnsavedWarning}
                toggleShowUnsavedWarning={toggleShowUnsavedWarning}

                close={close}
            />

            <LocalModal
                show={showHandInAlert}
                toggleShow={toggleShowHandInAlert}
                component={
                    <Alert
                        toggleShow={toggleShowHandInAlert} 
                    
                        title="Missende antwoorden"
                        description="Je heb niet alle vragen beantwoord. Weet je zeker dat je wilt inleveren?"
                    
                        buttonClasses={[
                            new buttonClass({
                                title: "Doorgaan met de toets",
                                buttonFunc: () => toggleShowHandInAlert(false),
                            }),
                            new buttonClass({
                                title: "Toch inleveren",
                                buttonFunc: () => sendUserAnswers(SUBMIT, userSettings),
                            })
                        ]}
                    />
                }

                loading={loading && examClass ? true : false}
            />

            <LocalModal
                show={showUserSettings || showUserSettingsBeforeSubmission}
                toggleShow={showUserSettings ? toggleShowUserSettings : toggleShowUserSettingsBeforeSubmission}

                component={
                    <SetUserSettings
                        userSettings={userSettings}
                        updateUserSettings={updateUserSettings}
                        fetchUserSettings={fetchUserSettings}
                        submitExam={showUserSettings ? null : submitExam}
                        toggleShow={showUserSettings ? toggleShowUserSettings : toggleShowUserSettingsBeforeSubmission}
                    />
                }
            />

            <LocalModal
                show={showLoadingGenerateFeedback}
                toggleShow={() => null}

                component={
                    <GenerateFeedbackLoading />
                }
            />

            <Redux
                showSuccess={false}
                varId={REDUX_ERRORS}

                reduxVar={generateFeedbackError}
            />

        </div>
    )
}

export default TakeExam;
